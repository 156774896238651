import React, { createContext, useContext, useMemo } from 'react'

import { useMe } from '~/features/me/api'
import { defineAbilityFor } from '~/lib/authz/abilities'
import { type AppAbility } from '~/lib/authz/authz.types'
import { type AuthedPrincipal } from '~/server/modules/auth/auth.types'

type AuthzContextShape = {
  ability: AppAbility
  principal: AuthedPrincipal
}

export const AuthzContext = createContext<AuthzContextShape | null>(null)

export const useAuthzContext = () => {
  const context = useContext(AuthzContext)

  if (context === null)
    throw new Error('Must use AuthzContext within AuthzProvider')

  return context
}

export const AuthzProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { me: principal } = useMe()

  const ability = useMemo(() => defineAbilityFor(principal), [principal])

  return (
    <AuthzContext.Provider value={{ principal, ability }}>
      {children}
    </AuthzContext.Provider>
  )
}
