import { Flex } from '@chakra-ui/react'

import { EnforceLoginStatePageWrapper } from '@activesg/ui/components'
import { type GetLayout } from '@activesg/ui/types'

import { AppNavbar } from '~/components/AppNavbar'
import { SIGN_IN } from '~/constants/routes'
import { AuthzProvider } from '~/contexts'

export const AuthedLayout: GetLayout = (page) => {
  return (
    <EnforceLoginStatePageWrapper redirectTo={SIGN_IN}>
      <AuthzProvider>
        <Flex bg="base.canvas.alt" flex={1} flexDir="column" overflow="hidden">
          <AppNavbar />
          <Flex flex={1} overflow="auto">
            {page}
          </Flex>
        </Flex>
      </AuthzProvider>
    </EnforceLoginStatePageWrapper>
  )
}
